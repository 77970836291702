import { login } from "../../utils/login.js";
import { registration } from "../../utils/registration";
import { addErrors, validateSecondStep } from "../../utils/validate.js";
import { isError } from "../../utils/validation.js";
import { resetSignUpSteps } from "../../utils/togglePopup.js";
import { getValueFromBrowser } from "../../utils/trackingQueryHook.js";
import { sendGtagEvent } from "../../utils/gtag";
import { loginWithEmail } from "../../utils/utils.js";
import { eventCleanupFunctions } from "../../utils/togglePopup.js";

const disableSubmitButton = (button, res) => {
    button.disabled = res;
}

let isFilledInputs = {};

export const updateIsFilledInputs = () => {
    isFilledInputs = {
        loginForm: loginWithEmail() ? { password: false, email: false } : {password: false},
        signUpFormFirstStep: { email: false, password: false, phone: false },
    }
}
updateIsFilledInputs();

const checkInputs = (form) => {
    const formToCheck = isFilledInputs[form];
    let fillValue = true;
    const inputsToCheck = Object.values(formToCheck);
    for (const el of inputsToCheck) {
        if (!el) {
            fillValue = false;
            break;
        }
    }
    return fillValue;
}

const isCheckFillInput = (form, button) => {
    let fillValue = checkInputs(form);
    disableSubmitButton(button, !fillValue);
}

const checkSignUpInputsErrors = (form, button, errors, hints) => {
    let fillValue = true;
    fillValue = checkInputs(form);
    if (hints.some((hint) => hint.classList.contains('unsuccessful'))) {
        fillValue = false;
    }
    if (errors.some((err) => err.classList.contains('show'))) {
        fillValue = false;
    }
    disableSubmitButton(button, !fillValue);
}

const activateSignUpSecondStep = (button, textFields, dateFields, textErrors, dateErrors) => {
    const firstStep = document.querySelector('.first-step-section');
    const secondStep = document.querySelector('.second-step-section');
    const progressIndicator = document.querySelector('.progress-indicator');
    const progressIndicatorLine = document.querySelector('.progress-indicator-line');
    const progressIndicatorInactive = document.querySelector('.progress-indicator_inactive');
    const banner = document.querySelector('#SignUp .popup__banner');
    const dateErrorContainer = document.querySelector(".error__message.signup-dob");
    if (firstStep.classList.contains('is__active')) {
        firstStep.classList.remove('is__active');
        secondStep.classList.add('is__active');
        [progressIndicator, progressIndicatorLine, progressIndicatorInactive, banner].forEach((elem) => {
            elem?.classList.add('second-step');
        });
        progressIndicator.addEventListener('click', resetSignUpSteps);
        const {addEventListeners, removeEventListeners} = validateSecondStep();
        addEventListeners();
        eventCleanupFunctions.push(removeEventListeners);
        const allTextFieldsValid = textFields.every(field => field.value);
        const allDateFieldsValid = dateFields.every(field => field.dataset.value);
        if (!allTextFieldsValid) {
            textErrors.forEach((errorContainer) => addErrors(errorContainer));
        } 
        if (!allDateFieldsValid) {
            dateErrors.forEach((errorContainer) => addErrors(errorContainer));
        }
        if (!allTextFieldsValid || !allDateFieldsValid || dateErrorContainer.innerHTML) {
            disableSubmitButton(button, true);
        }
    }
}

const activateForms = () => {
    const loginForm = document.getElementById('login-form');
    const loginSubmitButton = document.getElementById('login-form-submit-button');
    const signUpSubmitButton = document.getElementById('signup-form-submit-button');
    const signUpForm = document.getElementById('signup-form');
    const fieldsErrorMessage = signUpForm.querySelectorAll(".error__message");
	const hintPasswordElements = [...signUpForm.querySelectorAll('.signup-hint-password')];
    const nameInput = signUpForm.querySelector("#signup-name");
    const nameErrorContainer = signUpForm.querySelector(".error__message.signup-first-name");
	const surnameInput = signUpForm.querySelector("#signup-surname");
    const surnameErrorContainer = signUpForm.querySelector(".error__message.signup-last-name");
    const dateFields = [...signUpForm.querySelectorAll(".select__screen")];
	const dateSelects = [...signUpForm.querySelectorAll('.select__list.js-select-list')];
    const dateElements = [...dateFields, ...dateSelects];
    const dateFieldsErrorMessage = signUpForm.querySelectorAll('.signup__birthdate__row .error-message');

    if (loginForm) {
        loginForm.addEventListener('input', (e) => {
            isFilledInputs.loginForm[e.target.name] = e.target?.value?.length > 0;
            if (loginSubmitButton) {
                isCheckFillInput('loginForm', loginSubmitButton);
            }
        });
        loginForm.addEventListener('submit', (e) => {
            e.preventDefault();
            login(e);
        });
    }
    if (signUpForm) {
        signUpForm.addEventListener('input', (e) => {
            isFilledInputs.signUpFormFirstStep[e.target.name] = e.target?.value?.length > 0;
            if (signUpSubmitButton) {
                checkSignUpInputsErrors(
                    'signUpFormFirstStep',
                    signUpSubmitButton, 
                    [...fieldsErrorMessage, ...dateFieldsErrorMessage], 
                    hintPasswordElements
                );
                setTimeout(() => {
                    if (!signUpSubmitButton.disabled) {
                        disableSubmitButton(signUpSubmitButton, isError);
                    }
                }, 500);
            }
        });
        const inputs = Object.keys(isFilledInputs.signUpFormFirstStep);
        inputs.forEach((input) => {
            signUpForm[input].addEventListener('blur', () => {
                setTimeout(() => {
                    if (!signUpSubmitButton.disabled) {
                        disableSubmitButton(signUpSubmitButton, isError);
                    }
                }, 500);
            });
        });
        signUpForm.addEventListener('submit', async (e) => {
            e.preventDefault();
            const secondStep = document.querySelector('.second-step-section');
            if (!secondStep.classList.contains('is__active')) {
                sendGtagEvent('sign_up_form_first_step_passed', {
                    affiliate_id: getValueFromBrowser('affiliateId') || '',
                });
                activateSignUpSecondStep(
                    signUpSubmitButton, 
                    [nameInput, surnameInput], 
                    dateFields,
                    [nameErrorContainer, surnameErrorContainer],
                    dateFieldsErrorMessage,
                );
                dateElements.forEach((elem) => {
                    elem.addEventListener('click', () => {
                        checkSignUpInputsErrors(
                            'signUpFormFirstStep',
                            signUpSubmitButton, 
                            [...fieldsErrorMessage, ...dateFieldsErrorMessage], 
                            hintPasswordElements,
                        );
                    });
                });
            } else {
                await registration(e);
            }
        });
    }
}

activateForms();

export {
    disableSubmitButton,
    isFilledInputs,
};