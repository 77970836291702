import {checkResetPassMethod, confirmSMSCode, resetPass} from '../../utils/forgotPass';
import { openPopupById, closeAllPopups } from "../../utils/togglePopup";
import { selectValidMessagesPassword } from "../../utils/validate_password";

const changeCurrentPopup = (e, attributeData) => {
    // is used to close current popup/step
    closeAllPopups();
    // to open new popup
    const dataAttr = attributeData.includes('data')
        ? e.target
            ? e.target.getAttribute(attributeData)
            : e.getAttribute(attributeData)
        : attributeData;
    openPopupById(dataAttr);
};

const resetForgotPasswordInputErrors = () => {
    const forms = document.querySelectorAll('.forgotPass__popup__body > form');
    if (forms.length) {
        forms.forEach((form) => {
            const input = form.querySelector('input');
            input?.classList.remove('error');
            const errorSpan = form.querySelector('.input__row > p.error__message');
            if (errorSpan) errorSpan.innerHTML = '';
        });
    }
};

const checkEmailStep2 = () => {
    const checkUserEmailForm = document.getElementById('check__user__email__form');
    const checkUserEmailButton = document.getElementById('check__user__email__button');
    const userEmailInput = document.getElementById('user__email');
    const userEmailError = document.getElementById('forgotPass-form-email-error');

    userEmailInput?.addEventListener('input', (input) => {
        checkUserEmailButton.disabled = (input.target.value.length < 2);
        resetForgotPasswordInputErrors();
    });

    userEmailInput?.addEventListener('focusout', (input) => {
        if (input.target.value.length < 2) {
            userEmailInput.classList.add('error');
            userEmailError.innerHTML = 'Tu correo electrónico es incorrecto';
        }
    });

    checkUserEmailForm?.addEventListener('submit', (data) => {
        checkResetPassMethod(
            data,
            userEmailInput,
            userEmailError,
            checkUserEmailButton,
            changeCurrentPopup
        );
    });
};

checkEmailStep2();

const checkPhoneStep2 = () => {
    const checkUserPhoneForm = document.getElementById('check__user__phone__form');
    const checkUserPhoneButton = document.getElementById('check__user__phone__button');
    const userPhoneInput = document.getElementById('user__phone');
    const userPhoneError = document.getElementById('forgotPass-form-phone-error');

    userPhoneInput?.addEventListener('input', (input) => {
        checkUserPhoneButton.disabled = (input.target.value.length !== 10);
        resetForgotPasswordInputErrors();
    });

    userPhoneInput?.addEventListener('focusout', (input) => {
        checkUserPhoneButton.disabled = (input.target.value.length !== 10);
        if (input.target.value.length !== 10) {
            userPhoneInput.classList.add('error');
            userPhoneError.innerHTML = 'El número de teléfono es incorrecto';
        }
    });

    checkUserPhoneForm?.addEventListener('submit', (data) => {
        checkResetPassMethod(
            data,
            userPhoneInput,
            userPhoneError,
            checkUserPhoneButton,
            changeCurrentPopup
        );
    });
};

checkPhoneStep2();

const checkPhoneStep3 = () => {
    const checkUserSMSForm = document.getElementById('check__user__sms__form');
	const SMSCodeDigitInputs = checkUserSMSForm?.querySelectorAll('input');
	const SMSCodeDigitError = document.getElementById('user__sms__code__error');

	let historyCharStr = [];
	const setHistoryCharStr = (charStr) => {
		historyCharStr = [...historyCharStr, charStr];
	}

	const setCurrentCode = async (codeInputs) => {
		const code = codeInputs.length ? [...codeInputs].map((input) => input.value) : '';
		const confirmationCode = code.join('');
		if (confirmationCode.length === 6) {
			await confirmSMSCode(
				SMSCodeDigitError,
				confirmationCode,
				document.getElementById('forgotPass__user__phone').innerHTML,
				changeCurrentPopup
			);
		}
	};

	SMSCodeDigitInputs?.forEach((input) => {
		input.addEventListener('focusin', (e) => {
			e?.target.parentNode.classList.add('active');
		});
		input.addEventListener('focusout', (e) => {
			e?.target.parentNode.classList.remove('active');
		});
		input.addEventListener('keydown', async (e) => {
			const { code, target } = e;
			const currentIndex = Number(target.dataset.numberCodeInput);
			const previousIndex = currentIndex - 1;
			const nextIndex = currentIndex + 1;

			const hasPreviousIndex = previousIndex >= 0;
			const hasNextIndex = nextIndex <= 5;

			switch (code) {
				case 'ArrowLeft':
				case 'ArrowUp':
					if (hasPreviousIndex) {
						SMSCodeDigitInputs[previousIndex]?.focus();
					}
					e.preventDefault();
					break;
				case 'ArrowRight':
				case 'ArrowDown':
					if (hasNextIndex) {
						SMSCodeDigitInputs[nextIndex]?.focus();
					}
					e.preventDefault();
					break;
				case 'Backspace':
					if (!e.target.value.length && hasPreviousIndex) {
						SMSCodeDigitInputs[previousIndex].value = null;
						SMSCodeDigitInputs[previousIndex]?.focus();
					}
					break;
				default:
					break;
			}

			const charStr = e?.key.toString();
			if (charStr !== 'Backspace' && charStr !== 'Delete') {
				// Control Event for Windows And Meta Event for IOS (click on cmd)
				if (
					!(
						charStr === 'v' &&
						(historyCharStr[historyCharStr.length - 1] === 'Control' ||
							historyCharStr[historyCharStr.length - 1] === 'Meta')
					)
				) {
					if (!charStr.match(/^[0-9-]*$/)) {
						e.preventDefault();
					}
				}
			}
			setHistoryCharStr(charStr);
			await setCurrentCode(SMSCodeDigitInputs);
		});
		input.addEventListener('input', async ({ target }) => {
			checkUserSMSForm?.querySelector('.input__line')?.classList?.remove('error');
			SMSCodeDigitError.innerHTML = '';

			if (!/[0-9]$/.test(target.value)) {
				return (target.value = null);
			}

			if (!target.value.length) {
				await setCurrentCode(SMSCodeDigitInputs);
				return (target.value = null);
			}

			const inputLength = target.value.length;
			let currentIndex = Number(target.dataset.numberCodeInput);

			if (inputLength > 1) {
				const inputValues = target.value.split('');

				inputValues.forEach((value, valueIndex) => {
					const nextValueIndex = currentIndex + valueIndex;

					if (nextValueIndex >= 6) {
						return;
					}
					if (
						SMSCodeDigitInputs[currentIndex].value &&
						inputLength <= 2
					) {
						SMSCodeDigitInputs[currentIndex].value = `${value}`;
					} else {
						SMSCodeDigitInputs[nextValueIndex].value = `${value}`;
					}
				});

				currentIndex += inputValues.length - 2;
			}

			const nextIndex = currentIndex + 1;

			if (nextIndex < 6) {
				SMSCodeDigitInputs[nextIndex]?.focus();
			}
			await setCurrentCode(SMSCodeDigitInputs);
		});
	});
};

checkPhoneStep3();

const resetPasswordStep = () => {
	// Reset Password step
	const resetPassSubmitForm = document.getElementById('user__reset__pass__form');
	const resetPassSubmitButton = resetPassSubmitForm.querySelector('#reset__user__pass');
	const newPassInput = resetPassSubmitForm.querySelector('#user__new__pass');
	const newPassCheckInput = resetPassSubmitForm.querySelector('#user__new__pass__copy');
	const newPassCheckError = resetPassSubmitForm.querySelector('.error__message');
	const confirmationCode = document.getElementById('confirmation__code');

	const resetPassHints = resetPassSubmitForm?.querySelectorAll(".reset-hint-password");
	const passwordValidationMessages = resetPassSubmitForm.querySelectorAll(".unsuccessful");
	newPassInput?.addEventListener('input', (input) => {
		selectValidMessagesPassword(input.target.value, resetPassHints);
		resetForgotPasswordInputErrors();
	});

	newPassInput?.addEventListener('focusout', (input) => {
		resetPassSubmitButton.disabled = (input.target.value !== newPassCheckInput.value || passwordValidationMessages?.length > 0);
		if (input.target.value !== newPassCheckInput.value && newPassCheckInput.value !== '') {
			newPassCheckInput.classList.add('error');
			newPassCheckError.innerHTML = 'La contraseña es incorrecta';
		} else {
			resetForgotPasswordInputErrors();
		}
	});

	newPassCheckInput?.addEventListener('input', (input) => {
		resetPassSubmitButton.disabled = (input.target.value !== newPassInput.value || passwordValidationMessages.length > 0);
		resetForgotPasswordInputErrors();
	});

	newPassCheckInput?.addEventListener('focusout', (input) => {
		resetPassSubmitButton.disabled = (input.target.value !== newPassInput.value);
		if (input.target.value !== newPassInput.value && newPassInput.value !== '') {
			newPassCheckInput.classList.add('error');
			newPassCheckError.innerHTML = 'La contraseña es incorrecta';
		} else {
			resetForgotPasswordInputErrors();
		}
	});

	resetPassSubmitForm?.addEventListener('submit',  (e) => {
		e.preventDefault();
		resetPass(
			confirmationCode.innerHTML,
			newPassCheckInput,
			newPassCheckError,
			resetPassSubmitButton,
			changeCurrentPopup
		);
	});

	// Forgot Password popups logic end
}

resetPasswordStep();