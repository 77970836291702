import {openPopupById, closeAllPopups, getQueryParamFromUrl, checkIfAuthPopup} from "../../utils/togglePopup";
import { getValueFromBrowser, setValueToBrowser } from "../../utils/trackingQueryHook";
import { simplifyLogin } from "../simplifyLogin";
import { updateIsFilledInputs } from "./activate_forms";

const handleTogglePopup = () => {
    document.querySelectorAll('[data-open-popup]').forEach((button) => {
        button.addEventListener('click', () => {
            const openedPopupAttr = button.getAttribute('data-open-popup');
            const popupId = button.dataset.openPopup;
            const product = button.dataset.product;

            if (openedPopupAttr === 'quitPopup') {
                openPopupById(popupId, product);
                return;
            }
            closeAllPopups();
            openPopupById(popupId, product);

            const loginWithEmail = getValueFromBrowser('loginWithEmail');
            if (loginWithEmail === 'loginWithEmail') {
                setValueToBrowser('loginWithEmail', 'false');
                simplifyLogin();
                updateIsFilledInputs();
            }
        });
    });
    
    document.querySelectorAll('[data-close-popup]').forEach((closeButton) => {
        closeButton.addEventListener('click', (e) => {
            e.stopPropagation();
            const typeAttr = closeButton.getAttribute('data-type-popup');
            if (typeAttr === 'quitPopup') {
                document.querySelector('[data-type-popup].is__open').classList.remove('is__open');
                return;
            }

            if ((e.target === e.currentTarget) || (
                e.target.tagName.toLowerCase() === 'img' &&
                e.currentTarget.tagName.toLowerCase() === 'button'
            )) {
                if (e.target.closest('#SignUp')) {
                    openPopupById('quitPopup', '');
                    return
                }
                closeAllPopups();
            }
        });
    });

    const modalFromUrl = getQueryParamFromUrl('modal');
    if (modalFromUrl && checkIfAuthPopup(modalFromUrl)) {
        openPopupById(modalFromUrl);
    }
};

handleTogglePopup();
