import { getValueFromBrowser, setValueToBrowser } from "../utils/trackingQueryHook";
import { loginWithEmail } from "../utils/utils";

export const simplifyLogin = () => {
    const userFirstNameElements = document.querySelectorAll('#userFirstName');
    const checkboxRow = document.querySelector('#Login .checkbox__row');
    const loginFullButton = document.querySelector('#loginFullButton');
    const loginTitle = document.querySelector('#loginTitle');
    const userFirstName = localStorage.getItem('firstName');
    const loginEmailElements = document.querySelectorAll(`[data-login-email]`);
    const elementsToRemove = document.querySelectorAll(`[data-login-email="${!loginWithEmail()}"]`);

    loginEmailElements.forEach(element => {
        element.style.display = '';
    });
    elementsToRemove.forEach(element => {
        element.style.display = 'none';
    });

    if (!loginWithEmail()) {
        checkboxRow.classList.add("checkBoxWrapper__center");
        loginTitle.classList.add("mb_small");

        userFirstNameElements?.forEach(element => {
            element.innerText = userFirstName;
        })
    } else {
        checkboxRow.classList.remove("checkBoxWrapper__center");
        loginTitle.classList.remove("mb_small");
    }

    loginFullButton?.addEventListener('click', () => {
        setValueToBrowser('loginWithEmail', 'loginWithEmail');
        simplifyLogin();
    })
}

if (getValueFromBrowser('loginWithEmail') === 'loginWithEmail') {
    setValueToBrowser('loginWithEmail', 'false');
    simplifyLogin();
}

simplifyLogin();