const {WINPOT_CASINO_URL} = require('../../utils/consts');

export const appIconNav = {
    sportIcon: '/images/navbar/mobile-app/ios-sport.png',
    casinoIcon: '/images/navbar/mobile-app/ios-casino.png'
}

export const headerNav = [
    {
        id: 0,
        name: 'header.nav.casino',
        link: `${WINPOT_CASINO_URL}casino`,
        linkType: 'casino',
        mobileIcon: 'assets/images/casino.svg',
    },
    {
        id: 1,
        name: 'header.nav.liveCasino',
        link: `${WINPOT_CASINO_URL}live/lobby`,
        linkType: 'casino-live',
        mobileIcon: 'assets/images/casino-en-vivo.svg',
    },
    {
        id: 2,
        name: 'header.nav.sports',
        link: `${WINPOT_CASINO_URL}sport`,
        linkType: 'sport',
        mobileIcon: 'assets/images/sport.svg',
    },
    {
        id: 3,
        name: 'header.nav.promotions',
        link: `${WINPOT_CASINO_URL}promotions`,
        linkType: 'promo',
        mobileIcon: 'assets/images/promotions.svg',
    },
    {
        id: 4,
        name: 'header.nav.winclub',
        link: `${WINPOT_CASINO_URL}winclub`,
        linkType: 'vip',
        mobileIcon: 'assets/images/vip.svg',
    },
    {
        id: 5,
        name: 'header.nav.calendar',
        link: `${WINPOT_CASINO_URL}calendario-navideno`,
        linkType: 'calendar',
        mobileIcon: 'assets/images/calendar-icon.svg',
    },
    {
        id: 6,
        name: 'header.nav.referral',
        link: `${WINPOT_CASINO_URL}referral`,
        linkType: 'referral',
        mobileIcon: 'assets/images/referral.svg',
    },
];

const ESideMenuCategories = {
    promotions: 'header.nav.promotions',
    casino: 'header.nav.casino',
    liveCasino: 'header.nav.liveCasino',
    sports: 'header.nav.sports',
    winclub: 'header.nav.winclub',
    referral: 'header.nav.referral',
    download: 'header.nav.download',
    gamification: 'gamification.menu.title',
    tournament: 'header.nav.tournament',
};

const ESideMenuLanguages = {
    spanish: 'header.lang.spanish',
    english: 'header.lang.english',
}

const ESideMenuSubCategoryGamification = {
    overview: 'gamification.menu.overview',
    casino: 'gamification.menu.casino',
    sport: 'gamification.menu.sport',
    store: 'gamification.menu.store',
    mail: 'gamification.menu.mail',
};

const ESideMenuSubCategoryPromo = {
    casino: 'header.nav.promotions.casino',
    sport: 'header.nav.promotions.sport',
};

const ESideMenuSubCategoryTournament = {
    casino: 'header.nav.tournament.casino',
    sport: 'header.nav.tournament.sport',
};

const ESideMenuSubCategoryCasino = {
    top: 'header.nav.casino.top',
    new: 'header.nav.casino.new',
    popular: 'header.nav.casino.popular',
    hot: 'header.nav.casino.hot',
    cold: 'header.nav.casino.cold',
    jackpot: 'header.nav.casino.jackpot',
    megaways: 'header.nav.casino.megaways',
    buyBonus: 'header.nav.casino.buyBonus',
    scraped: "header.nav.casino.scraped",
};

const ESideMenuSubCategoryLiveCasino = {
    topLiveCasino: 'header.nav.liveCasino.topLiveCasino',
    roulette: 'header.nav.liveCasino.roulette',
    blackJack: 'header.nav.liveCasino.blackJack',
    pocker: 'header.nav.liveCasino.pocker',
    baccaratAndDice: 'header.nav.liveCasino.baccaratAndDice',
    gameShow: 'header.nav.liveCasino.gameShow',
};

const ESideMenuSubCategorySports = {
    live: 'header.nav.sports.live',
    virtualSports: 'header.nav.sports.virtualSports',
    streaming: 'header.nav.sports.streaming',
};

const ESideMenuLinks = {
    promotions: `${WINPOT_CASINO_URL}promotions`,
    promotionsCasino: `${WINPOT_CASINO_URL}promotions?tab=Casino`,
    promotionsSport: `${WINPOT_CASINO_URL}promotions?tab=Sport`,
    casino: `${WINPOT_CASINO_URL}casino`,
    casinoTop: `${WINPOT_CASINO_URL}casino/category/Top_Juegos`,
    casinoNew: `${WINPOT_CASINO_URL}casino/category/Nuevo`,
    casinoPopular: `${WINPOT_CASINO_URL}casino/category/Popular`,
    casinoHot: `${WINPOT_CASINO_URL}casino/category/HOT?group=true`,
    casinoCold: `${WINPOT_CASINO_URL}casino/category/COLD?group=true`,
    casinoJackpot: `${WINPOT_CASINO_URL}casino/category/Jackpots`,
    casinoMegaways: `${WINPOT_CASINO_URL}casino/category/Megaways`,
    casinoBuyBonus: `${WINPOT_CASINO_URL}casino/category/Comprar_Bono`,
    casinoScraped: `${WINPOT_CASINO_URL}casino/category/RASPADITOS`,
    liveCasino: `${WINPOT_CASINO_URL}live/lobby`,
    liveCasinoTop: `${WINPOT_CASINO_URL}live/lobby/category/top_en_vivo_juegos`,
    liveCasinoRoulette: `${WINPOT_CASINO_URL}live/lobby/category/Ruleta_En_Vivo`,
    liveCasinoBlackJack: `${WINPOT_CASINO_URL}live/lobby/category/Black Jack_En_Vivo`,
    liveCasinoPoker: `${WINPOT_CASINO_URL}live/lobby/category/Poker_En_Vivo`,
    liveCasinoBaccarat: `${WINPOT_CASINO_URL}live/lobby/category/Baccarat_En_Vivo`,
    liveCasinoShow: `${WINPOT_CASINO_URL}live/lobby/category/Shows_En_Vivo`,
    sports: `${WINPOT_CASINO_URL}sport`,
    sportsLive: `${WINPOT_CASINO_URL}live-sport`,
    sportsVirtual: `${WINPOT_CASINO_URL}virtual-sports`,
    streaming: `${WINPOT_CASINO_URL}sport#/streaming`,
    winclub: `${WINPOT_CASINO_URL}winclub`,
    referral: `${WINPOT_CASINO_URL}referral`,
    gamificationOverview: `${WINPOT_CASINO_URL}casino?modal=LoginPromo`,
    gamificationCasino: `${WINPOT_CASINO_URL}casino?modal=LoginPromo`,
    gamificationSport: `${WINPOT_CASINO_URL}casino?modal=LoginPromo`,
    gamificationStore: `${WINPOT_CASINO_URL}casino?modal=LoginPromo`,
    gamificationMail: `${WINPOT_CASINO_URL}casino?modal=LoginPromo`,
    tournament: `${WINPOT_CASINO_URL}torneo`,
    tournamentCasino: `${WINPOT_CASINO_URL}torneo?tab=Casino`,
    tournamentSport: `${WINPOT_CASINO_URL}torneo?tab=Sport`,
};

export const sideMenuLanguages = {
    es: {
        label: ESideMenuLanguages.spanish,
        Icon: 'assets/images/mexicoFlag.svg',
    },
    en: {
        label: ESideMenuLanguages.english,
        Icon: 'assets/images/englishFlag.svg',
    },
};

export const sidemenuNav = [
    {
        id: 0,
        label: ESideMenuCategories.gamification,
        Icon: 'assets/images/gamification.svg',
        gtag: 'sidemenu_item_gamification',
        href: `${WINPOT_CASINO_URL}casino?modal=LoginPromo`,
        items: [
            {
                label: ESideMenuSubCategoryGamification.overview,
                href: ESideMenuLinks.gamificationOverview,
                Icon: 'assets/images/gamification_overview.svg',
                gtag: 'sidemenu_item_gamification_overview',
            },
            {
                label: ESideMenuSubCategoryGamification.casino,
                href: ESideMenuLinks.gamificationCasino,
                Icon: 'assets/images/gamification_casino.svg',
                gtag: 'sidemenu_item_gamification_casino',
            },
            {
                label: ESideMenuSubCategoryGamification.sport,
                href: ESideMenuLinks.gamificationSport,
                Icon: 'assets/images/gamification_sport.svg',
                gtag: 'sidemenu_item_gamification_sport',
            },
            {
                label: ESideMenuSubCategoryGamification.store,
                href: ESideMenuLinks.gamificationStore,
                Icon: 'assets/images/gamification_store.svg',
                gtag: 'sidemenu_item_gamification_store',
            },
            {
                label: ESideMenuSubCategoryGamification.mail,
                href: ESideMenuLinks.gamificationMail,
                Icon: 'assets/images/gamification_mail.svg',
                gtag: 'sidemenu_item_gamification_mail',
            },
        ],
    },
    {
        id: 1,
        label: ESideMenuCategories.promotions,
        Icon: 'assets/images/promotions.svg',
        href: ESideMenuLinks.promotions,
        gtag: 'sidemenu_item_promotions',
        items: [
            {
                label: ESideMenuSubCategoryPromo.casino,
                href: ESideMenuLinks.promotionsCasino,
                Icon: 'assets/images/promotionsCasino.svg',
                gtag: 'sidemenu_item_promotions_casino',
            },
            {
                label: ESideMenuSubCategoryPromo.sport,
                href: ESideMenuLinks.promotionsSport,
                Icon: 'assets/images/promotionsSport.svg',
                gtag: 'sidemenu_item_promotions_sport',
            },
        ],
    },
    {
        id: 2,
        label: ESideMenuCategories.tournament,
        Icon: 'assets/images/tournament.svg',
        href: ESideMenuLinks.tournament,
        gtag: 'sidemenu_item_tournament',
        items: [
            {
                label: ESideMenuSubCategoryTournament.casino,
                href: ESideMenuLinks.tournamentCasino,
                Icon: 'assets/images/promotionsCasino.svg',
                gtag: 'sidemenu_item_tournament_casino',
            },
            {
                label: ESideMenuSubCategoryTournament.sport,
                href: ESideMenuLinks.tournamentSport,
                Icon: 'assets/images/promotionsSport.svg',
                gtag: 'sidemenu_item_tournament_sport',
            },
        ],
    },
    {
        id: 3,
        label: ESideMenuCategories.referral,
        Icon: 'assets/images/referral.svg',
        href: ESideMenuLinks.referral,
        gtag: 'sidemenu_item_referral',
    },
    {
        id: 4,
        label: ESideMenuCategories.casino,
        Icon: 'assets/images/casino.svg',
        href: ESideMenuLinks.casino,
        gtag: 'sidemenu_item_casino',
        items: [
            {
                label: ESideMenuSubCategoryCasino.top,
                href: ESideMenuLinks.casinoTop,
                Icon: 'assets/images/top.svg',
                gtag: 'sidemenu_item_casino_top',
            },
            {
                label: ESideMenuSubCategoryCasino.new,
                href: ESideMenuLinks.casinoNew,
                Icon: 'assets/images/new.svg',
                gtag: 'sidemenu_item_casino_new',
            },

            {
                label: ESideMenuSubCategoryCasino.popular,
                href: ESideMenuLinks.casinoPopular,
                Icon: 'assets/images/popular.svg',
                gtag: 'sidemenu_item_casino_popular',
            },
            {
                label: ESideMenuSubCategoryCasino.hot,
                href: ESideMenuLinks.casinoHot,
                Icon: 'assets/images/hot.svg',
                gtag: 'sidemenu_item_casino_hot',
            },
            {
                label: ESideMenuSubCategoryCasino.cold,
                href: ESideMenuLinks.casinoCold,
                Icon: 'assets/images/cold.svg',
                gtag: 'sidemenu_item_casino_cold',
            },
            {
                label: ESideMenuSubCategoryCasino.jackpot,
                href: ESideMenuLinks.casinoJackpot,
                Icon: 'assets/images/jackpot.svg',
                gtag: 'sidemenu_item_casino_jackpot',
            },
            {
                label: ESideMenuSubCategoryCasino.megaways,
                href: ESideMenuLinks.casinoMegaways,
                Icon: 'assets/images/megaways.svg',
                gtag: 'sidemenu_item_casino_megaways',
            },
            {
                label: ESideMenuSubCategoryCasino.buyBonus,
                href: ESideMenuLinks.casinoBuyBonus,
                Icon: 'assets/images/buyBonus.svg',
                gtag: 'sidemenu_item_casino_buy_bonus',
            },
            {
                label: ESideMenuSubCategoryCasino.scraped,
                href: ESideMenuLinks.casinoScraped,
                Icon: 'assets/images/scraped.svg',
                gtag: 'sidemenu_item_casino_scraped',
            },
        ],
    },
    {
        id: 5,
        label: ESideMenuCategories.liveCasino,
        Icon: 'assets/images/casino-en-vivo.svg',
        href: ESideMenuLinks.liveCasino,
        gtag: 'sidemenu_item_casino_live',
        items: [
            {
                label: ESideMenuSubCategoryLiveCasino.topLiveCasino,
                href: ESideMenuLinks.liveCasinoTop,
                Icon: 'assets/images/topLiveCasino.svg',
                gtag: 'sidemenu_item_casino_live_top',
            },
            {
                label: ESideMenuSubCategoryLiveCasino.roulette,
                href: ESideMenuLinks.liveCasinoRoulette,
                Icon: 'assets/images/roulette.svg',
                gtag: 'sidemenu_item_casino_live_roulette',
            },
            {
                label: ESideMenuSubCategoryLiveCasino.blackJack,
                href: ESideMenuLinks.liveCasinoBlackJack,
                Icon: 'assets/images/blackJack.svg',
                gtag: 'sidemenu_item_casino_live_blackjack',
            },
            {
                label: ESideMenuSubCategoryLiveCasino.pocker,
                href: ESideMenuLinks.liveCasinoPoker,
                Icon: 'assets/images/pocker.svg',
                gtag: 'sidemenu_item_casino_live_poker',
            },
            {
                label: ESideMenuSubCategoryLiveCasino.baccaratAndDice,
                href: ESideMenuLinks.liveCasinoBaccarat,
                Icon: 'assets/images/baccaratAndDice.svg',
                gtag: 'sidemenu_item_casino_live_baccarat_and_dice',
            },
            {
                label: ESideMenuSubCategoryLiveCasino.gameShow,
                href: ESideMenuLinks.liveCasinoShow,
                Icon: 'assets/images/gameShow.svg',
                gtag: 'sidemenu_item_casino_live_gameshow',
            },
        ],
    },
    {
        id: 6,
        label: ESideMenuCategories.sports,
        Icon: 'assets/images/sport.svg',
        href: ESideMenuLinks.sports,
        gtag: 'sidemenu_item_sport',
        items: [
            {
                label: ESideMenuSubCategorySports.live,
                href: ESideMenuLinks.sportsLive,
                Icon: 'assets/images/sport-en-vivo.svg',
                gtag: 'sidemenu_item_sport_live',
            },
            {
                label: ESideMenuSubCategorySports.virtualSports,
                href: ESideMenuLinks.sportsVirtual,
                Icon: 'assets/images/virtuales.svg',
                gtag: 'sidemenu_item_sport_virtual',
            },
            {
                label: ESideMenuSubCategorySports.streaming,
                href: ESideMenuLinks.streaming,
                Icon: 'assets/images/streaming.svg',
                gtag: 'sidemenu_item_sport_streaming',
            },
        ],
    },
    {
        id: 7,
        label: ESideMenuCategories.winclub,
        Icon: 'assets/images/vip.svg',
        href: ESideMenuLinks.winclub,
        gtag: 'sidemenu_item_winclub',
    },
];

export const infoSubmenu = [
    {
        name: 'header.nav.information.item_1',
        link: `${WINPOT_CASINO_URL}info/open-an-account`,
        gtag: 'sidemenu_item_information_open_an_account',
    },
    {
        name: 'header.nav.information.item_2',
        link: `${WINPOT_CASINO_URL}info/deposits-to-your-account`,
        gtag: 'sidemenu_item_information_deposits_to_your_account',
    },
    {
        name: 'header.nav.information.item_3',
        link: `${WINPOT_CASINO_URL}info/bonus-information`,
        gtag: 'sidemenu_item_information_bonus_information',
    },
    {
        name: 'header.nav.information.item_4',
        link: `${WINPOT_CASINO_URL}info/betting-rules`,
        gtag: 'sidemenu_item_information_betting_rules',
    },
];
