import {disableSubmitButton} from "../js/popups/activate_forms";
import {
    getTrackingValues,
    getValueFromBrowser,
    removeValueFromBrowser,
    setValueToBrowser
} from "./trackingQueryHook";
import {birthDay, birthMonth, birthYear} from './validate_birthday';
import {loginRegistrationAdditionalHeaders} from "./utils";
import {initializeSession, redirectAfterRegistration} from "./session";
import {internalSetCookie} from "./cookies";
import {handleError} from "./errorHandler";
import {APP_SERVER_URL} from "../../../config";
import {sendGtagEvent} from "./gtag";

export const registration = async (data) => {
    const signUpSubmitButton = document.getElementById('signup-form-submit-button');

    if (signUpSubmitButton?.disabled === false) {
        disableSubmitButton(signUpSubmitButton, true);
        let sessionId = null;
        if (seon) {
            seon.init();
            sessionId = await seon.getSession();
        }

        const referralCode = getValueFromBrowser('referralCode');

        const birthDate = new Date(
            Date.UTC(birthYear, birthMonth - 1, birthDay, 0, 0, 0)
        );

        const formData = {
            email: data.target[0].value.trim(),
            password: data.target[1].value,
            phoneCode: '+52',
            phoneNumber: data.target[3].value,
            isPushNewsletter: data.target[4].checked,
            isPhoneNewsletter: data.target[4].checked,
            isEmailNewsletter: data.target[4].checked,
            allowPromotions: true,
            firstName: data.target[5].value.trim(),
            lastName: data.target[6].value.trim(),
            couponCode: data.target[10].value,
            birthDate,
        }
        const address = {
            street: '',
            city: '',
            zipCode: '',
            colonyMX: '',
            addressCountryAlfa2: '--',
        };
        const phone = `${formData.phoneCode}${formData.phoneNumber}`;

        const profileData = {
            language: 'es',
            address,
            gender: 'other',
            birthDate: formData.birthDate,
            firstName: formData.firstName,
            lastName: formData.lastName,
            middleName: '',
            password: formData.password,
            ...(formData.nickName && {nickName: formData.nickName.trim()}),
            isPushNewsletter: formData.isPushNewsletter,
            isPhoneNewsletter: formData.isPhoneNewsletter,
            isEmailNewsletter: formData.isEmailNewsletter,
            allowPromotions: formData.allowPromotions,
            citizenship: 'MX',
            email: formData.email,
            phone,
            currency: 'MXN',
            affiliateInformation: getTrackingValues(formData.couponCode),
            emailVerified: false,
            phoneVerified: false,
            country: undefined,
            city: undefined,
            zipCode: undefined,
            fingerprint: sessionId,
            ...(referralCode && {referralCode})
        };

        fetch(`${APP_SERVER_URL}player/crm/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...loginRegistrationAdditionalHeaders()
            },
            body: JSON.stringify(profileData)
        })
            .then((response) => {
                if (!response.ok) {
                    // If response is not ok, parse the response to get the error details
                    return response.json().then(error => {
                        return Promise.reject(error);
                    });
                }
                return response.json();
            })
            .then((response) => {
                initializeSession(response);
                setValueToBrowser('email', response?.email)
                localStorage.setItem('userId', response?.id);
                internalSetCookie('isRegistration', 'done');
                internalSetCookie('shouldRecieveBonus', response?.isOptBonusAvailable?.toString());
                setValueToBrowser('product', response?.product?.toString());
                const nowTime = new Date().getTime();
                const startInputTime = sessionStorage.getItem("startInputTime") || '';
                const completeRegistrationTime = nowTime - Number(startInputTime);
                sendGtagEvent('registration_completed', {
                    affiliate_id: getValueFromBrowser('affiliateId') || '',
                    time: completeRegistrationTime
                });
                removeValueFromBrowser('referralCode');
                internalSetCookie('userHasAccount', 'true');
                redirectAfterRegistration();
            })
            .catch((e) => {
                console.log(e)
                const {message, statusCode} = e;
                const signUpErrorContainer = document.querySelector('.signup-submit-error');
                if (
                    statusCode === 403 ||
                    message?.includes('rejected')
                ) {
                    handleError('Algo salió mal. Por favor contacte con soporte.', signUpErrorContainer)
                } else if (message.includes('duplicate')) {
                    const errorField = message.replace('duplicate.', '').split('.').join(', ');
                    handleError(`Este ${errorField} ya existe`, signUpErrorContainer)
                } else {
                    console.log('[Error]', message);
                }
                sendGtagEvent('registration_failed', {
                    affiliate_id: getValueFromBrowser('affiliateId') || '',
                });
            })
            .finally(() => {
                disableSubmitButton(signUpSubmitButton, true);
            })

    }
}
