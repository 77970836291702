export const showError = (field, errorMessage) => {
    field.innerHTML = errorMessage;
    field.classList.add('show');
};

export const hideError = (field) => {
    field.innerHTML = '';
    field.classList.remove('show');
};

export const handleError = (errorMessage, errorContainer) => {
    if (errorMessage) {
        showError(errorContainer, errorMessage);
    } else {
        hideError(errorContainer);
    }
};

export const getEmailErrorMessage = (message) => {
    if (message.includes('duplicate')) {
        return 'Ya existe un usuario con este correo electrónico';
    } else if (message === "Invalid domain" || message.includes('black list')) {
        return 'Tu e-mail es incorrecto';
    } else {
        return '';
    }
};

export const getPhoneErrorMessage = (message) => {
    if (message.includes('duplicate')) {
        return 'Ya existe un usuario con este teléfono';
    } else {
        return '';
    }
};
